// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Table, Button, notification } from 'antd';
import { useGetAssetBalancesMutation } from '../services/localApi';
import { checkAssetColumns } from './common';
import './common.css';


const CheckAssetBalancesComponent = () => {
    const [textBoxAssets, setTextBoxAssets] = useState('');
    const [getAssets, { data: responseGetAssets, getAssetError }] = useGetAssetBalancesMutation();
    const handleButtonCheckAssets = async () => {
      let inputAssets = ['USDT', 'BTC', 'ETH', 'BNB'];
      if (textBoxAssets !== '') {
        const tmp = textBoxAssets.split(',');
        inputAssets = inputAssets.concat(tmp);
      }
      try {
        await getAssets({ assets: inputAssets, user: localStorage.getItem('access_token') }).unwrap();
      } catch (err) {
        console.error('Get assets error details:', err);
        notification.error({
          message: 'Get asset',
          description: `Error : ${JSON.stringify(err)}`,
        });
      }
    };

    return (
      <div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '100px' }}> Balance of : </div>
          <input type="text" value={textBoxAssets} onChange={(e) => setTextBoxAssets(e.target.value)} />
          <Button type="primary" onClick={handleButtonCheckAssets}> Check </Button>
        </div> */}
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
          <div style={{ width: '100px' }}> Balance of : </div>
          <input style={{ marginLeft: '5px' }} type="text" value={textBoxAssets} onChange={(e) => setTextBoxAssets(e.target.value)} />
          <div style={{ position: 'absolute', right: '0' }}>
            <Button className="normal-button" onClick={handleButtonCheckAssets}> Check </Button>
          </div>
        </div>
        {responseGetAssets
              && (
              <div>

                <Table
                  columns={checkAssetColumns}
                  dataSource={responseGetAssets}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
              </div>
)}

      </div>
    );
  };

export default CheckAssetBalancesComponent;
