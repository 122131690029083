import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import {Button, notification } from 'antd';
import { useStateContext } from '../app/ContextProvider';
import { useRestartServerMutation } from '../services/localApi';

const News = () => {
  const {
 isLoggedIn, setIsLoggedIn, user, setUser,
} = useStateContext();
  
const [restartServer] = useRestartServerMutation();

  const handleRestartButton = () => {
    
    try {
      const response = restartServer({ user: localStorage.getItem('access_token')}).unwrap();
      notification.success({
        message: 'Success',
        description: `Restart success !`,
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: `error : ${err?.data}`,
      });
    }
  };

  return (
    <div>
      <Button className="normal-button" onClick={handleRestartButton}> Restart ws </Button>

    </div>
  );
};

export default News;
