// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Table, Button, notification } from 'antd';
import { useGetSymbolSpreadAllPairsMutation } from '../services/localApi';
import { checkSymbolColumns } from './common';

import './common.css';

const CheckSymbolSpreadComponent = () => {
    const [textBoxCheckSymbol, setTextBoxCheckSymbol] = useState('');
    const [getSymbol, { data: responseCheckSymbol, checkSymbolError }] = useGetSymbolSpreadAllPairsMutation();

    const handleButtonCheckSymbol = async () => {
      try {
        await getSymbol({ symbol: textBoxCheckSymbol, user: localStorage.getItem('access_token') }).unwrap();
      } catch (err) {
        console.error('Check symbol error details:', err);
        notification.error({
          message: 'Check symbol',
          description: `Error : ${JSON.stringify(err)}`,
        });
      }
    };
    return (
      <div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '100px' }}>Spread of :</div>
          <input type="text" value={textBoxCheckSymbol} onChange={(e) => { setTextBoxCheckSymbol(e.target.value); }} />
          <Button type="primary" onClick={handleButtonCheckSymbol}> Check </Button>
        </div> */}
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
          <div style={{ width: '100px' }}>Spread of :</div>
          <input style={{ marginLeft: '5px' }} type="text" value={textBoxCheckSymbol} onChange={(e) => { setTextBoxCheckSymbol(e.target.value); }} />
          <div style={{ position: 'absolute', right: '0' }}>
            <Button className="normal-button" onClick={handleButtonCheckSymbol}> Check </Button>
          </div>
        </div>
        {responseCheckSymbol
                && (
                <div>
                  {/* Assets: {JSON.stringify(assets)} */}
                  <Table
                    columns={checkSymbolColumns}
                    dataSource={responseCheckSymbol}
                    size="small"
                    style={{ marginTop: '20px' }}
                    pagination={false}
                  />
                </div>
)}

      </div>
    );
  };

export default CheckSymbolSpreadComponent;
