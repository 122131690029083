// src/PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStateContext } from './app/ContextProvider';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useStateContext();

    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => ((isLoggedIn) ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            ))}
      />
    );
};

export default PrivateRoute;
