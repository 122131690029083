import React from 'react';

import { Switch, Route, Link } from 'react-router-dom';
import { Layout, Typography, Space } from 'antd';
import { Positions, Homepage, News, Cryptocurrencies, CryptoDetails, Navbar, Spreads, Assets } from './components';
import Login from './Login';

import PrivateRoute from './PrivateRoute';
import './App.css';

const App = () => (
  <div className="app">
    <div className="navbar">
      <Navbar />
    </div>
    <div className="main">
      <Layout>
        <div className="routes">
          <Switch>
            <Route path="/cryptocurrencies" component={Cryptocurrencies} />
            <Route path="/login" component={Login} />
            <Route exact path="/" component={Homepage} />
            <PrivateRoute path="/assets" component={Assets} />
            <PrivateRoute path="/positions" component={Positions} />
            <PrivateRoute path="/spreads" component={Spreads} />
            {/* <PrivateRoute path="/crypto/:coinId" component={CryptoDetails} /> */}
            <PrivateRoute path="/news" component={News} />
          </Switch>
        </div>
      </Layout>
      <div className="footer">
        <Typography.Title level={5} style={{ color: 'white', textAlign: 'center' }}>
          <Link to="/">
          Copyright © 2024 ZDT Trading Inc.
          </Link> <br />
          All Rights Reserved.
        </Typography.Title>
        <Space>
          <Link to="/">Home</Link>
          <Link to="/assets">Assets</Link>
          <Link to="/positions">Positions</Link>
          <Link to="/spreads">Spreads</Link>
          <Link to="/news">News</Link>
        </Space>
      </div>
    </div>
  </div>
);
export default App;
