import React, { createContext, useState, useContext } from 'react';

export const StateContext = createContext();

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
      <StateContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn }}>
        {children}
      </StateContext.Provider>
    );
};
export const useStateContext = () => useContext(StateContext);
