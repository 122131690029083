import { useEffect } from 'react';
import { useRefreshTokenMutation } from './localApi';

const useTokenRefresh = () => {
  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    const interval = setInterval(async () => {
      const storedRefreshToken = localStorage.getItem('refresh_token');
      if (storedRefreshToken) {
        try {
          const response = await refreshToken({ token: storedRefreshToken });
          const { accessToken } = response.data;
          localStorage.setItem('access_token', accessToken);
        } catch (error) {
        //   console.error('Error refreshing token:', error);
          // Handle token refresh failure (e.g., force logout)
        }
      }
    }, 10 * 60 * 1000); // Get new token every 10 minutes
    // }, 20 * 1000);

    return () => clearInterval(interval);
  }, [refreshToken]);
};

export default useTokenRefresh;
